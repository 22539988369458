import React, { useEffect, useRef, useState } from "react";
import * as styles from "./sectionLayout.module.css";
import { getPublicUrl } from "../FetchImage";
import {
  SecondaryCard,
  SecondaryCircleCard,
  SecondarySquareCard,
} from "../cards/Cards";
import { KpointPlayerFromEmbedCode } from "../../lib/kpoint/KpointPlayerFromEmbedCode";
import { PopupButton } from "../buttons/Buttons";
import { Link } from "gatsby";
import BackgroundWave from "./BackgroundWave";
import RenderMedia from "../Images";
import {
  RenderSvg,
  ScreenSize,
  baseUrl,
  isAllowedDomain,
  scheduleDemo,
} from "../../utils/helpers/helper";
import { PlatformTitleDesc } from "./TitleDescription";
export default function SectionLayout({
  children,
  bgColor,
  About,
  Platform,
  People,
  myStory,
  IsEmbedded,
  usecases,
  careers,
  className,
  lifeatkpoint,
  ...rest
}) {
  return (
    <div
      className={`${
        About
          ? styles?.AboutSectionContainer
          : Platform
          ? styles?.PlatformSectionContainer
          : usecases
          ? styles?.UseCaseSectionContainer
          : myStory
          ? styles?.mystoryContainer
          : People
          ? styles?.peopleContainer
          : careers
          ? styles?.careersContainer
          : lifeatkpoint // Handle lifeatkpoint separately
          ? styles.lifeatkpointContainer
          : styles?.container
      } ${className}`}
      {...rest}
    >
      <BackgroundWave bgColor={bgColor} IsEmbedded={IsEmbedded} />
      {children}
    </div>
  );
}

export const SecondaryCircleCardLayout = ({ children }) => {
  return <div className={styles?.SecondaryCircleCardLayout}>{children}</div>;
};

export const BannerVideo = ({ mediaName, folder }) => {
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      controls={false}
      className={styles?.BannerVideo}
    >
      <source src={getPublicUrl(folder, mediaName)} type="video/mp4" />
    </video>
  );
};

export const SecondarySquareCardLayout = ({
  title,
  showMinute,
  tagText,
  image,
  ...rest
}) => {
  const RenderDiv = rest?.to ? Link : "div";
  return (
    <RenderDiv className={styles?.SecondarySquareCardLayout} {...rest}>
      <div className={styles?.SecondarySquareCardLayoutContent}>
        <h2 className="caption">{title}</h2>
        <div className={styles?.SecondarySquareCardLayoutSecondaryText}>
          {showMinute && (
            <p className={`caption ${styles?.minutes}`}>{showMinute} read</p>
          )}
        </div>
      </div>
      <SecondarySquareCard image={image} />
    </RenderDiv>
  );
};

export const PeopleCardLayout = ({ children, bgvideo }) => {
  return (
    <div className={styles?.peopleCardLayout}>
      {bgvideo && (
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          className={styles?.bgVideo}
        >
          <source
            src={getPublicUrl(bgvideo?.folder, bgvideo?.name)}
            type="video/mp4"
          />
        </video>
      )}
      {children}
    </div>
  );
};

export const ScheduleADemo = ({
  header,
  openPopup,
  setOpenPopup,
  isMobile,
}) => {
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [error, setError] = useState(false);
  const emailError = useRef(null);
  const submitButtonRef = useRef(null);
  const formRef = useRef(null);

  const handleEmailValid = (e) => {
    const { value } = e ? e.target : {};

    if (!value) return;
    if (
      value.includes("@") &&
      value.split("@")[1] &&
      value.split("@")[1].split(".")[1] &&
      value.split("@")[1].split(".")[1]?.length > 1
    ) {
      if (!isAllowedDomain(value)) {
        emailError.current.innerHTML = "Please enter a valid business email Id";
        emailError.current.style.display = "block";
        submitButtonRef.current.disabled = true;
      } else {
        emailError.current.style.display = "none";
        submitButtonRef.current.disabled = false;
      }
    } else {
      emailError.current.style.display = "none";
      submitButtonRef.current.disabled = false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, phone, name } = event.target || {};
    if (
      (email?.value || phone?.value || name?.value) &&
      isAllowedDomain(email?.value)
    ) {
      submitButtonRef.current.disabled = true;
      const data = {
        FullName: name?.value,
        PhoneNumber: phone?.value,
        Email: email?.value,
      };
      scheduleDemo({
        data,
        formError: setError,
        fromSuccess: setIsFormSuccess,
      });
      setIsFormSuccess("success");
    } else {
      emailError.current.innerHTML = "Please enter a valid business email Id";
      emailError.current.style.display = "block";
      submitButtonRef.current.disabled = true;
    }
  };
  useEffect(() => {
    if (openPopup) {
      setIsFormSuccess(false);
      if (emailError.current) emailError.current.style.display = "none";
      if (formRef.current) formRef.current.reset();
    }
  }, [openPopup]);

  useEffect(() => {
    let setTimeoutId;
    if (isFormSuccess) {
      if (header && isMobile) {
        setTimeoutId = setTimeout(() => {
          setOpenPopup(null);
          if (submitButtonRef.current) submitButtonRef.current.disabled = true;
          //trigger a post message to parent window to close the iframe popup
          window.parent.postMessage({ eventName: "formSubmitted" }, "*");
        }, 1500);
      } else {
        setTimeoutId = setTimeout(() => {
          setOpenPopup(null);
          if (submitButtonRef.current) submitButtonRef.current.disabled = false;
          window.parent.postMessage({ eventName: "formSubmitted" }, "*");
        }, 5000);
      }
    }
    return () => clearTimeout(setTimeoutId);
  }, [isFormSuccess]);
  return (
    <div className={styles?.ScheduleADemo}>
      {header && (
        <>
          <RenderMedia
            mediaName={"kpointPopupLogo.svg"}
            className={styles?.kpointPopupLogo}
          />
          <h2 className={styles?.connectH2}>Contact Us</h2>
        </>
      )}
      {isFormSuccess && !header ? (
        <div className={styles?.successMessageBox}>
          <div className={styles?.contentBox}>
            <h1 className="heading-quaternary-lg">
              Thank you for reaching us out!
            </h1>
            <p className="subheading-tertiary-sm">
              We will get back to you soon.
            </p>
          </div>
          <p
            className="banner-secondary-sm"
            style={{ letterSpacing: "-0.14px" }}
          >
            Any queries? Please contact{" "}
            <a href="mailto:queries@kpoint.com">queries@kpoint.com</a>
          </p>
        </div>
      ) : (
        <>
          {!header && (
            <>
              <h1
                className={`${styles?.ScheduleADemoHeading} heading-quaternary-lg`}
              >
                Schedule a demo
              </h1>
              <p className={`${styles?.ScheduleADemoDesc} body-sm `}>
                Welcome to KPOINT, where we revolutionized business
                communications through interactive business.
              </p>{" "}
            </>
          )}

          <form
            ref={formRef}
            className={styles?.ScheduleADemoForm}
            style={header ? { gap: "0.4rem", justifyContent: "center" } : {}}
            onSubmit={(e) => handleSubmit(e)}
          >
            {!header && (
              <>
                <input
                  className="button-sm"
                  name="name"
                  type="text"
                  placeholder="Full Name"
                  required
                />
                <input
                  className="button-sm"
                  name="phone"
                  type="number"
                  placeholder="Phone No"
                  required
                />
                {!isMobile && (
                  <input
                    className="button-sm"
                    name="email"
                    type="email"
                    placeholder="Please enter your work email"
                    onChange={(e) => handleEmailValid(e)}
                    required
                    autoFocus
                  />
                )}
              </>
            )}

            {isMobile ? (
              <>
                <input
                  style={
                    header
                      ? {
                          width: "100%",
                          height: "60px",
                          borderRadius: "4px",
                          background: "white",
                          border: "1px solid #00000080",
                          padding: "0 25px",
                        }
                      : {}
                  }
                  className="button-sm"
                  name="email"
                  type="email"
                  placeholder="Please enter your work email"
                  onChange={(e) => handleEmailValid(e)}
                  required
                  autoFocus
                />
                {isFormSuccess && (
                  <p className={styles?.formSubmitSuccess} style={{ left: 0 }}>
                    Thank you ! We will get back soon.
                  </p>
                )}
              </>
            ) : (
              header && (
                <>
                  <div className={styles?.emailInputContainer}>
                    <input
                      className="button-sm"
                      name="email"
                      type="email"
                      placeholder="Please enter your work email"
                      onChange={(e) => handleEmailValid(e)}
                      required
                      autoFocus
                    />
                    <PopupButton
                      type={"submit"}
                      label={"Submit"}
                      ref={submitButtonRef}
                      className={styles?.demoButton}
                    />
                  </div>
                  {isFormSuccess && (
                    <p className={styles?.formSubmitSuccess}>
                      Thank you ! We will get back soon.
                    </p>
                  )}
                </>
              )
            )}

            <div
              ref={emailError}
              style={{
                display: "none",
                // background: "red",
                color: "red",
                padding: "5px 25px",
                width: "100%",
                textAlign: "center",
                // maxWidth: "314px",
                // margin: "auto",
                borderRadius: "22px",
              }}
            ></div>
            {(!header || (header && isMobile)) && (
              <PopupButton
                style={
                  header && isMobile
                    ? {
                        background: "#009BFF",
                        width: "fit-content",
                        marginTop: "0.5rem",
                        marginLeft: "auto",
                      }
                    : {}
                }
                type={"submit"}
                label={"Submit"}
                ref={submitButtonRef}
                className={styles?.demoButton}
              />
            )}

            {/* {header && (
              <p className={`body-sm ${styles?.ScheduleADemoThankyouText}`}>
                Thank you for reaching out! We will revert shortly.
              </p>
            )} */}
          </form>
        </>
      )}
    </div>
  );
};

export const Share = ({ link }) => {
  const socialLinks = [
    {
      label: "linkedIn",
      url: "https://www.linkedin.com/sharing/share-offsite/?url=",
    },
    {
      label: "whatsApp",
      url: "https://wa.me/?text=", //${+919100345880}
    },
    {
      label: "twitter",
      url: "https://twitter.com/intent/tweet?url=",
    },
    {
      label: "facebook",
      url: "https://www.facebook.com/sharer/sharer.php?u=",
    },
  ];
  return (
    <div className={styles?.shareContainer}>
      <h1 className={`${styles?.shareTitle} heading-quaternary-lg`}>Share</h1>
      <div className={styles?.shareSocialContainer}>
        {socialLinks?.map((e, index) => {
          return (
            <div
              onClick={() => {
                navigator.clipboard
                  .writeText(link || window.location.href)
                  .then(() => {
                    if (link) {
                      let url = e?.url + link;
                      window.open(url, "_blank");
                    } else if (e?.url) {
                      window.open(e.url + window.location.href, "_blank");
                    }
                  })
                  .catch((error) => {
                    console.error("Error writing to clipboard:", error);
                  });
              }}
              key={index}
              className={styles?.shareSocialLink}
            >
              <RenderSvg
                IconName={e.label.toLowerCase()}
                IconType="brands"
                style={{ height: "20px", color: "#ffffff" }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const CaseStudyLinkUseCaseSection = ({
  layoutHashLinkId,
  bgcolor,
  title,
  description,
  caseStudies,
  embedCode,
  personalizationInfo,
  media,
}) => {
  return (
    <>
      <SectionLayout id={layoutHashLinkId} bgColor={bgcolor} usecases={true}>
        <PlatformTitleDesc title={title} description={description} />
        {embedCode ? (
          <div className={styles?.embedVideosContainer}>
            <KpointPlayerFromEmbedCode
              embedCode={embedCode}
              containerClassNames={styles?.videoContainer}
              params={{
                autoplay: false,
                muted: true,
                playerSkinType: "social",
                loop: true,
                search: false,
                toc: false,
              }}
              playWhenInViewOnly={true}
              personalizationInfo={JSON.stringify(personalizationInfo ?? {})}
            />
          </div>
        ) : (
          <SecondaryCard media={media} />
        )}
        <SecondaryCircleCardLayout>
          {caseStudies?.map((e, i) => {
            const Media =
              e?.DesktopMedia?.localFile?.childImageSharp?.gatsbyImageData;
            // ? e?.MobileMedia?.localFile?.childImageSharp?.gatsbyImageData
            // : e?.DesktopMedia?.localFile?.childImageSharp?.gatsbyImageData;

            return (
              <React.Fragment key={i}>
                <SecondaryCircleCard
                  to={
                    "/resources/casestudy/" +
                    e?.caseStudyName?.replaceAll(" ", "-")
                  }
                  circleImage={true}
                  image={
                    Media
                      ? { gatsbyImageData: Media }
                      : {
                          mediaName: "problemsImgTwo.jpg",
                          folder: "cardImages/home/problems",
                        }
                  }
                />
              </React.Fragment>
            );
          })}
        </SecondaryCircleCardLayout>
      </SectionLayout>
    </>
  );
};
