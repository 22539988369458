import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useContext,
  useMemo,
} from "react";
import * as styles from "./KpointPlayer.module.css";
import { useInView } from "react-intersection-observer";
import { KpointPlayerContext } from "../../context/KpointPlayerContext";
import { graphql, useStaticQuery } from "gatsby";
import EmailGate from "./EmailGate";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useObserver } from "../../context/ObserverContext";

/**
 * Merges two widget configuration objects.  It combines the configurations of widgets,
 * concatenating lists within widgets if present, or otherwise merging widget properties.
 * This helps in dynamically configuring the player widgets based on different inputs.
 *
 * @param {Object} config1 - The first configuration object.
 * @param {Object} config2 - The second configuration object.
 * @returns {Object} - The merged configuration object.
 */
const mergeWidgetConfig = function (config1 = {}, config2 = {}) {
  let mergedConfig = { ...config1 };
  for (let widgetName in config2) {
    let widgetConfig = mergedConfig[widgetName];
    if (!widgetConfig) {
      mergedConfig[widgetName] = config2[widgetName];
    } else if (
      typeof widgetConfig === "object" &&
      Array.isArray(widgetConfig.list)
    ) {
      widgetConfig.list = [...widgetConfig.list, ...config2[widgetName].list];
    } else {
      mergedConfig[widgetName] = { ...widgetConfig, ...config2[widgetName] };
    }
  }
  return mergedConfig;
};

/**
 * The KpointPlayer component provides a customizable video player using kPoint library.
 * It supports dynamic video IDs based on device orientation and merges external widget configurations.
 *
 * @param {Object} props - Props for configuring the video player.
 * @param {React.Ref} ref - Ref forwarded for external control of the video player.
 */
const KpointPlayer = forwardRef((props, ref) => {
  const {
    videoId,
    fakeVideoId,
    horizontalVideoId,
    verticalVideoId,
    fakeHorizontalVideoId,
    fakeVerticalVideoId,
    host,
    params = {},
    externalWidgetsConfig = {},
    packageId,
    personalizationInfo,
    thumbnailPath,
    isVerticalVideo,
    containerClassNames = "",
    videoClassNames = "",
    state = "PUBLISHED",
    playWhenInViewOnly = true,
    children,
    onVideoLoad = () => {},
    onVideoRemove = () => {},
    onVideoEnd = () => {},
  } = props;

  const { isMuteRef, addPlayer, removePlayer } =
    useContext(KpointPlayerContext);
  const childRef = useRef();
  // const [customformOpened, setCustomformOpened] = useState(false);
  const customformOpenRef = useRef(false);
  const [emailGateOpened, setEmailGateOpened] = useState(false);
  const playerRef = useRef(null);

  const playerIsPlayingTimeout = useRef(null);

  const { observe, unobserve } = useObserver();

  const [videoState, setVideoState] = useState(null);

  const isVisibleRef = useRef(false);
  // const { ref: inLoadedViewRef, inView: inLoadedView } = useInView({
  //   threshold: 0.1,
  //   rootMargin: ` ${
  //     typeof window !== "undefined" ? window.innerHeight : 0
  //   }px 0px ${typeof window !== "undefined" ? window.innerHeight : 0}px 0px`,
  //   triggerOnce: true,
  // });

  // const setRefs = useCallback(
  //   (node) => {
  //     childRef.current = node;
  //     inViewRef(node);
  //     // setLoadedViewRef(node);
  //   },
  //   [inViewRef]
  // );

  // const setLoadedViewRef = useCallback(
  //   (node) => {
  //     inLoadedViewRef(node);
  //   },
  //   [inLoadedViewRef]
  // );
  /**
   * Initializes the video player with the provided configuration.
   * It fetches additional package details if a packageId is provided and merges widget configurations.
   */

  useEffect(() => {
    const handelStateChange = (event) => {
      if (
        playerRef.current &&
        event.data === playerRef.current.playStates.ENDED
      ) {
        onVideoEnd();
      }
    };

    if (playerRef.current) {
      playerRef.current.addEventListener("onStateChange", handelStateChange);
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener(
          "onStateChange",
          handelStateChange
        );
      }
    };
  }, [onVideoEnd]);

  const initPlayer = async () => {
    try {
      const options = {
        kvideoId: isSmartPlayer()
          ? isMobile()
            ? verticalVideoId
            : horizontalVideoId
          : videoId,
        videoHost: host,
        params,
        externalWidgetsConfig,
      };

      if (packageId) {
        let packageVideoId = isSmartPlayer()
          ? isMobile()
            ? fakeVerticalVideoId ?? verticalVideoId
            : fakeHorizontalVideoId ?? horizontalVideoId
          : fakeVideoId ?? videoId;

        let packageDetails = await fetch(
          `https://${host}/api/v3/videos/${packageVideoId}/dyn/embed?id=${packageId}&state=${state}`
        );
        packageDetails = await packageDetails.json();
        const embedCode = packageDetails.videoEmbedcode;

        const parser = new DOMParser();
        const doc = parser.parseFromString(embedCode, "text/html");

        const embedCodeDiv = doc.querySelector("div");

        //expect divElement other than elements in the body

        if (embedCodeDiv) {
          const body = doc.querySelector("body");
          const head = doc.querySelector("head");
          const bodyElements = Array.from(body.children).filter(
            (element) => element !== embedCodeDiv
          );

          let scripts = [];
          bodyElements.forEach((element) => {
            if (element.tagName === "SCRIPT") {
              scripts.push(element);
            }
          });
          Array.from(head.children).forEach((element) => {
            if (element.tagName === "SCRIPT") {
              scripts.push(element);
            }
          });

          scripts.forEach((script) => {
            const newScript = document.createElement("script");
            if (script.src) {
              if (script.src.includes("videofront-vega")) {
                return;
              }
              newScript.src = script.src;
            } else {
              newScript.textContent = script.innerHTML;
            }
            if (script.type === "module") {
              newScript.type = "module";
            }
            document.body.appendChild(newScript);
          });

          let markup = Array.from(head.children)
            .map((element) => element.outerHTML)
            .join("");
          markup += bodyElements.map((element) => element.outerHTML).join("");
          childRef.current.parentElement.querySelector(
            `.${styles?.markupTemplateContainer}`
          ).innerHTML = markup;

          const videoParams = JSON.parse(
            embedCodeDiv.dataset.videoParams || "{}"
          );
          options.params = { ...options.params, ...videoParams };

          if (params["add-widgets"]) {
            options.params["add-widgets"] += `,${videoParams["add-widgets"]}`;
          }
          options.externalWidgetsConfig = mergeWidgetConfig(
            externalWidgetsConfig,
            JSON.parse(embedCodeDiv.dataset.widgetsConfig || "{}")
          );
          options.personalizationInfo =
            embedCodeDiv.dataset.personalizationInfo || personalizationInfo;
          options.thumbnailPath =
            embedCodeDiv.dataset.thumbnailPath || thumbnailPath;
        }
      }

      //Add the local muted state to the player
      options.params.muted = isMuteRef.current;

      if (playWhenInViewOnly) {
        options.params.autoplay = false;
      }

      // Initialize the player with the provided configuration
      const playerInstance = window.kPoint.Player(childRef.current, options);
      playerRef.current = playerInstance;

      // Show the player once it is loaded
      playerInstance.addEventListener("loaded", () => {
        addPlayer(playerInstance);
        childRef.current.classList.remove(styles?.notLoaded);
        childRef.current.parentElement
          .querySelector(`.${styles?.playerOverlay}`)
          .classList.remove(styles?.notLoaded);
        childRef.current.parentElement
          .querySelector(`.${styles?.thumbnail}`)
          ?.classList.remove(styles?.notLoaded);

        // Play the video if it should play when in view and is currently in 70% is visible
        if (playWhenInViewOnly && isVisibleRef.current) {
          playerInstance.playVideo();
        }
      });
      playerInstance?.addEventListener("timeupdate", () => {
        varifyCorrectVideoPlaying(playerInstance);
      });
      playerInstance.addEventListener("customformOpen", () => {
        customformOpenRef.current = true;
      });

      playerInstance.addEventListener("customformClose", () => {
        customformOpenRef.current = false;
      });

      onVideoEnd(playerInstance);
      onVideoLoad(playerInstance);
    } catch (error) {
      console.error("Failed to initialize the KpointPlayer:", error);
      // Handle initialization failure (e.g., show a fallback UI)
    }
  };

  /**
   * Determines if the current device is mobile based on the user agent.
   * @returns {boolean} - True if the device is mobile, false otherwise.
   */
  const isMobile = () =>
    typeof window !== "undefined" &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  /**
   * Checks if the player should use smart logic for video IDs based on device orientation.
   * @returns {boolean} - True if both horizontal and vertical video IDs are provided, false otherwise.
   */
  const isSmartPlayer = () => horizontalVideoId && verticalVideoId;
  const [gccId, setGccId] = useState(
    isSmartPlayer()
      ? isMobile()
        ? verticalVideoId
        : horizontalVideoId
      : videoId
  );

  const data = useStaticQuery(graphql`
    query StrapiVideo {
      allStrapiVideo {
        edges {
          node {
            id
            videoId
            host
            title
            thumbnail {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);

  const videoDetails = useMemo(() => {
    return data.allStrapiVideo.edges.find((video) => {
      return video.node.videoId === gccId;
    });
  }, [gccId]);

  const image = getImage(
    videoDetails?.node?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData
  );

  const varifyCorrectVideoPlaying = (playerInstance) => {
    if (!isVisibleRef?.current && playWhenInViewOnly && playerInstance) {
      playerInstance?.pauseVideo();
    }
  };

  const varifyPlayerIsPlaying = () => {
    const player = playerRef.current;
    if (!player) return;
    if (
      player.getPlayState() === player.playStates.UNSTARTED &&
      isVisibleRef.current
    ) {
      player.playVideo();
      playerIsPlayingTimeout.current = setTimeout(() => {
        varifyPlayerIsPlaying();
      }, 200);
    } else {
      clearTimeout(playerIsPlayingTimeout.current);
    }
  };

  useImperativeHandle(ref, () => ({
    playVideo: () => playerRef.current?.playVideo(),
    rePlayVideo: () => {
      playerRef.current.rePlayVideo();
    },
    pauseVideo: () => {
      playerRef.current?.pauseVideo();
    },
    mute: () => {
      playerRef.current?.mute();
      playerRef.current?.setVolume(0);
    },
    unmute: () => {
      playerRef.current?.unmute();
      playerRef.current?.setVolume(1);
    },
    seekTo: (time) => {
      playerRef.current.seekTo(time);
    },
    getCurrentTime: () => {
      return playerRef.current.getCurrentTime();
    },
    getDuration: () => {
      return playerRef.current.getDuration();
    },
    getVolume: () => {
      return playerRef.current.getVolume();
    },
    setVolume: (volume) => {
      playerRef.current.setVolume(volume);
    },
    setWidgetsConfig: (widgetsConfig) => {
      playerRef.current.setWidgetsConfig(widgetsConfig);
    },
    setWidgetConfig: (widgetsConfig, widgetName) => {
      playerRef.current.setWidgetsConfig({
        [widgetName]: widgetsConfig[widgetName],
      });
    },

    togglePlayPauseVideo: () => {
      if (!playerRef.current) {
        return;
      }
      if (
        playerRef.current.getPlayState() ===
        playerRef.current.playStates.PLAYING
      ) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
    },

    loadVideoById: (newVideoId) => {
      if (playerRef.current && playerRef.current.loadVideoById) {
        playerRef.current.loadVideoById(newVideoId);
        setGccId(newVideoId);
      } else {
        console.log("loadVideoById is not available");
      }
    },

    playStates: () => {
      if (playerRef.current && playerRef.current.playStates.ENDED) {
        console.log("video ended");
      }
    },

    isVideoEnded: () => {
      return (
        playerRef.current &&
        playerRef.current.getPlayState() === playerRef.current.playStates.ENDED
      );
    },

    inView: isVisibleRef.current,
    playWhenInViewOnly: playWhenInViewOnly,

    postCustomEvent: (eventName, args = {}) => {
      playerRef?.current?.postCustomEvent(eventName, args);
    },
  }));

  //
  useEffect(() => {
    const element = childRef.current;

    if (element) {
      // Define callbacks
      //todo: add it in useCallback
      const inViewCallback = (inView) => {
        isVisibleRef.current = inView;
        if (!playWhenInViewOnly || customformOpenRef.current) {
          return;
        }
        if (inView) {
          playerRef.current?.playVideo?.();
          playerIsPlayingTimeout.current = setTimeout(() => {
            varifyPlayerIsPlaying();
          }, 200);
        } else {
          playerRef.current?.pauseVideo?.();
          clearTimeout(playerIsPlayingTimeout.current);
        }
      };

      const loadCallback = (shouldLoad) => {
        if (shouldLoad) {
          if (window.kPoint) {
            initPlayer();
          } else {
            document.addEventListener("playerSilkLoaded", initPlayer);
          }
        }
      };

      observe(element, inViewCallback, loadCallback);
    }
    function handleOnClickButton(event) {
      const { p0, p1 } = event.detail;
      if (p0 !== gccId) {
        return;
      }

      if (p1 === "emailgate") {
        customformOpenRef.current = true;

        setEmailGateOpened(true);
        playerRef?.current?.pauseVideo();
      }
    }

    document.addEventListener("kpw-onclick-button", handleOnClickButton);
    return () => {
      document.removeEventListener("kpw-onclick-button", handleOnClickButton);
      onVideoRemove(playerRef.current);
      removePlayer(playerRef.current);
      unobserve(element);
    };
  }, []);

  return (
    <>
      <div className={`${styles?.videoContainer} ${containerClassNames}`}>
        <div
          ref={childRef}
          data-ar={
            isSmartPlayer()
              ? isMobile()
                ? "9:16"
                : "16:9"
              : isVerticalVideo
              ? "9:16"
              : "16:9"
          }
          className={`kpoint-embedded-video ${styles?.embedCode} ${styles?.notLoaded} ${videoClassNames}`}
        ></div>
        <div className={`${styles?.playerOverlay} ${styles?.notLoaded}`}>
          {children}
          {emailGateOpened && (
            <EmailGate
              onClose={() => {
                setEmailGateOpened(false);
                customformOpenRef.current = false;

                playerRef.current.playVideo();
              }}
              playerRef={playerRef}
            />
          )}
        </div>
        <div className={`${styles?.thumbnail} ${styles?.notLoaded}`}>
          {image && (
            <>
              <GatsbyImage
                image={image}
                alt="thumbnail"
                className={styles?.image}
                aspectRatio={
                  isSmartPlayer()
                    ? isMobile()
                      ? "9/16"
                      : "16/9"
                    : isVerticalVideo
                    ? "9/16"
                    : "16/9"
                }
                placeholder="blurred"
              />
              <div className={styles?.kpointSpinner}>
                <div
                  className={`${styles?.kpointSpinner__dot} ${styles?.kpointBlue}`}
                ></div>
                <div
                  className={`${styles?.kpointSpinner__dot} ${styles?.kpointRed}`}
                ></div>
                <div
                  className={`${styles?.kpointSpinner__dot} ${styles?.kpointGreen}`}
                ></div>
              </div>
              ;
            </>
          )}

          {/*<StaticImage*/}
          {/*    src={videoDetails?.node?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData}*/}
          {/*    alt="thumbnail"*/}
          {/*    placeholder="blurred"*/}
          {/*    className={styles.image}*/}
          {/*    data-ar={*/}
          {/*      isSmartPlayer()*/}
          {/*          ? isMobile()*/}
          {/*              ? "9:16"*/}
          {/*              : "16:9"*/}
          {/*          : isVerticalVideo*/}
          {/*              ? "9:16"*/}
          {/*              : "16:9"*/}
          {/*    }*/}
          {/*/>*/}
        </div>

        <div className={styles?.markupTemplateContainer}></div>
      </div>
    </>
  );
});

export default KpointPlayer;
